<script setup lang="ts">
withDefaults(defineProps<{
    isEnabled?: boolean;
    cornerRadius?: number;
    cornerSize?: number;
    backgroundProgress?: number;
}>(), {
    isEnabled: true,
    cornerRadius: 6,
    cornerSize: 20,
    backgroundProgress: undefined,
});

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
</script>

<template>
    <CommonButton
        class="button"
        :corners="{
            tl: { type: 'round', size: cornerRadius },
            tr: { type: 'round', size: cornerRadius },
            br: { type: 'angle', width: cornerSize, height: cornerSize },
            bl: { type: 'round', size: cornerRadius },
        }"
        :border="{ type: 'solid', width: 1.5 }"
        :is-enabled="isEnabled"
        :background-progress="backgroundProgress"

        @click="emit('click', $event)"
    >
        <slot name="icon" />

        <h1 class="button__text">
            <slot />
        </h1>
    </CommonButton>
</template>

<style scoped lang="scss">
.button {
    // TODO: extract colors
    --background-color: rgba(255, 255, 255, 0.07);
    --hover-background-color: #{lighten(rgba(255, 255, 255, 0.07), 5%)};
    --active-background-color: #{darken(rgba(255, 255, 255, 0.07), 5%)};

    --border-color: rgba(255, 255, 255, 0.44);
    --hover-border-color: #{lighten(rgba(255, 255, 255, 0.44), 5%)};
    --active-border-color: #{darken(rgba(255, 255, 255, 0.44), 5%)};

    &__text {
        font-family: #{var(--font-family, $font-family-base)};
        font-size: size(var(--font-size-raw, 22px));
        font-style: normal;
        font-weight: var(--font-weight, 600);
        line-height: normal;
        letter-spacing: var(--letter-spacing, size(2.42px));

        color: rgba(255, 255, 255, 0.77);
        transition: color $base-transition;
    }

    &:hover &__text {
        color: lighten(rgba(255, 255, 255, 0.77), 5%);
    }

    &:active &__text {
        color: darken(rgba(255, 255, 255, 0.77), 5%);
    }
}
</style>
