import createClient, { type Middleware } from 'openapi-fetch';
import type { components, paths } from '~/schemas/store';
import { authService } from '~/services/authService';
import eventService from '~/services/eventService';

export const enum PREMIUM_STORE_STATE {
    UNDEF,
    LOADING,
    IDLE,
    PURCHASING,
    PURCHASE_SUCCESS,
    PURCHASE_ERROR
}

export type PurchaseResult =
    PREMIUM_STORE_STATE.PURCHASE_SUCCESS |
    PREMIUM_STORE_STATE.PURCHASE_ERROR |
    PREMIUM_STORE_STATE.IDLE

// Extend the Window interface
declare global {
    interface Window {
        __closePurchasePopup?: () => void;
    }
}

export class PremiumStoreService {
    private _client?: ReturnType<typeof createClient<paths>>;

    init(host: string) {
        const authMiddleware: Middleware = {
            async onRequest({ request }) {
                const accessToken = authService.accessToken;
                if (!accessToken) {
                    throw new Error('Access token is not defined');
                }

                request.headers.set('Authorization', `Bearer ${accessToken}`);

                return request;
            },
        };

        const baseUrl = this.buildUrl(host, true);

        this._client = createClient<paths>({ baseUrl });
        this._client.use(authMiddleware);
    }

    getItems = async() =>
        (await this.client?.GET('/catalog/items'))?.data;

    async purchaseItem(itemId: string): Promise<PurchaseResult>{
        console.debug('purchasing item', itemId);

        try {
            const order = await this.postOrder({
                catalogItemId: itemId,
                priceIdx: 0,
                settings: {
                    copperxSettings: {
                        successUrl: `${window.location.origin}/purchase`,
                        cancelUrl: `${window.location.origin}/purchase`,
                    },
                },
            });

            if (!order) {
                console.error('Purchase error.');
                return PREMIUM_STORE_STATE.PURCHASE_ERROR;
            }

            return order.status === 'IN_PROGRESS'
                ? this.makeExternalPurchase(order)
                : this.checkOrderStatus(order);
        } catch (error) {
            console.error(`Error purchasing item ${itemId}:`, error);
            return PREMIUM_STORE_STATE.PURCHASE_ERROR;
        }
    }

    private get client() {
        return throwIfNull(this._client, 'Client is not initialized');
    }

    private getOrder = async(id: string) =>
        (await this.client?.GET('/orders/{id}', { params: { path: { id } } }))?.data;

    private postOrder = async(body: components['schemas']['OrderRequestDTO']) =>
        (await this.client?.POST('/orders', { body }))?.data;

    private postXsollaOrder = async(body: components['schemas']['XsollaOrderRequestDTO']) =>
        (await this.client?.POST('/xsolla/orders', { body }))?.data;

    private async makeExternalPurchase(orderOriginal: components['schemas']['OrderResponseDTO']): Promise<PurchaseResult> {
        const orderId = orderOriginal.id;
        const paymentUrl = orderOriginal.paymentUrl;
        if (!orderId || !paymentUrl) {
            console.error('Purchase error: invalid response, no orderId or paymentUrl');
            return PREMIUM_STORE_STATE.PURCHASE_ERROR;
        }

        const [resultPromise, resolve] = makePromise<PurchaseResult>();
        let cancellationToken = false;

        const checkOrderStatus = async () => {
            let result: PurchaseResult;

            if (cancellationToken) {
                // TODO: cancel order

                result = PREMIUM_STORE_STATE.IDLE;
                resolve(result);
                return;
            }

            try {
                result = await retry(
                    async () => {
                        const orderUpdated = await this.getOrder(orderId);

                        if (!orderUpdated || orderUpdated.status === 'IN_PROGRESS') {
                            throw '';
                        }

                        return this.checkOrderStatus(orderUpdated);
                    },
                    undefined,
                    {
                        count: 1000,
                        timeout: 700,
                    });
            } catch {
                result = PREMIUM_STORE_STATE.PURCHASE_ERROR;
            }

            resolve(result);
        };

        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.zIndex = '40';
        iframe.style.top = '50%';
        iframe.style.left = '50%';
        iframe.style.transform = 'translate(-50%, -50%)';
        iframe.style.width = 'calc(100% - 150px)';
        iframe.style.height = '90vh';
        iframe.style.maxWidth = '1243px';
        iframe.style.maxHeight = '700px';
        iframe.style.backgroundImage = 'url("/images/copperXBg.webp")';
        iframe.src = paymentUrl;
        document.body.append(iframe);

        window.__closePurchasePopup = () => {
            checkOrderStatus();
            iframe.remove();
            eventService.premiumStore.emit('closeIframe');

            window.__closePurchasePopup = undefined;
        };

        eventService.premiumStore.emit('showIframe', { iframe, close: () => {
            cancellationToken = true;
            window.__closePurchasePopup?.();
        } });

        return resultPromise;
    }

    private checkOrderStatus(order: components['schemas']['OrderResponseDTO']): PurchaseResult {
        switch (order.status) {
            case 'COMPLETED':
                return PREMIUM_STORE_STATE.PURCHASE_SUCCESS;

            case 'ERROR':
                console.error(`Purchase error: ${order.error?.code} - ${order.error?.message}`);
                return PREMIUM_STORE_STATE.PURCHASE_ERROR;

            default:
                return PREMIUM_STORE_STATE.IDLE;
        }
    }

    private buildUrl(host: string, useSSL: boolean = true) {
        return `${useSSL ? 'https' : 'http'}://${host}/api`;
    }
}

export const premiumStoreService = new PremiumStoreService();
