import { AnyText } from '~/types/text';

export enum TUTORIAL_STEPS {
    MAIN,
    BOOST,
    MINING
}

export enum TUTORIAL_ELEMENTS {
    HEADER = 'HEADER',
    BOOST = 'BOOST',
    MINING = 'MINING',
}

export interface TutorialProps {
    steps: {
        header: string;
        secondHeader: string;
        text: AnyText;
        elToShow?: string;
    }[];
    initialTutorialStep: number;
    tutorialBackButton: boolean;
}
