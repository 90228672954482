export const VIDEO_ANIMATIONS = {
    BitBot: 'BitBot',
} as const;

export const BIT_BOT_STATES = {
    Idle: 'Idle',
    leftArmUp: 'leftArmUp',
    leftArmDownForApprove: 'leftArmDownForApprove',
    leftArmUpForApprove: 'leftArmUpForApprove',
    leftArmDown: 'leftArmDown',
    approveAnim: 'approveAnim',
} as const;
