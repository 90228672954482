<script setup lang="ts">
withDefaults(defineProps<{
    isEnabled?: boolean;
    cornerRadius?: number;
    cornerSize?: number;
}>(), {
    isEnabled: true,
    cornerRadius: 6,
    cornerSize: 20,
});

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
</script>

<template>
    <CommonButton
        class="button"
        :corners="{
            tl: { type: 'round', size: cornerRadius },
            tr: { type: 'round', size: cornerRadius },
            br: { type: 'angle', width: cornerSize, height: cornerSize },
            bl: { type: 'round', size: cornerRadius },
        }"
        :is-enabled="isEnabled"

        @click="emit('click', $event)"
    >
        <slot name="icon" />

        <h1 class="button__text">
            <slot />
        </h1>
    </CommonButton>
</template>

<style scoped lang="scss">
.button {
    --background-color: #{$color-primary};
    --hover-background-color: #{lighten($color-primary, 5%)};
    --active-background-color: #{darken($color-primary, 5%)};

    &__text {
        font-family: #{var(--font-family, $font-family-base)};
        font-size: size(var(--font-size-raw, 22px));
        font-style: normal;
        font-weight: var(--font-weight, 600);
        line-height: normal;
        letter-spacing: var(--letter-spacing, size(2.42px));

        color: $color-darkened;
        transition: color $base-transition;
    }
}
</style>
