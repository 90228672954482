import { ref } from 'vue';

import { PREMIUM_STORE_STATE, premiumStoreService } from '~/services/premiumStoreService';
import eventService from '~/services/eventService';

export interface ProviderItem {
    id: string;
    price: string;
    currency: string;
    name: string;
    quantity: number;
}

export interface StoreItemsData {
    items: Record<string, ProviderItem>;
}

export const usePremiumStore = defineStore('premiumStore', () => {
    const { premiumStoreHost } = storeToRefs(useRemoteConfigStore());
    const { isLoggedIn } = storeToRefs(useAuthStore());

    const state = ref(PREMIUM_STORE_STATE.UNDEF);
    const hardCurrencyPopupOpened = ref(false);
    const isInited = ref(false);

    watch(
        isLoggedIn,
        isLoggedIn => {
            if (isLoggedIn) {
                if (!premiumStoreHost.value) {
                    throw new Error('Premium store host is not defined');
                }

                premiumStoreService.init(premiumStoreHost.value);
                isInited.value = true;
            }
        },
        { immediate: true },
    );

    const storeItems = reactive<StoreItemsData>({ items: {} });

    const toggleHardCurrency = (value: boolean) => {
        hardCurrencyPopupOpened.value = value;

        if (value) {
            fetchStoreItems();
        }
    };

    const fetchStoreItems = async () => {
        console.debug('fetching store items');

        state.value = PREMIUM_STORE_STATE.LOADING;

        try{
            const items = await premiumStoreService.getItems() ?? [];

            storeItems.items = items.reduce<Record<string, ProviderItem>>(
                (acc, item) => {
                    if (!item.alias || !item.prices?.length) {
                        console.error(`Invalid provider item ${item.alias || '<unknown>'} - cannot find id, price list`);
                        return acc;
                    }

                    // take first price for now
                    const { amount, currency, itemClassId } = item.prices[0];
                    if (typeof amount !== 'string' || !amount) {
                        console.error(`Invalid provider item ${item.alias} - cannot find price amount`);
                        return acc;
                    }

                    const currencyId = currency || itemClassId;
                    if (!currencyId) {
                        console.error(`Invalid provider item ${item.alias} - cannot find currency`);
                        return acc;
                    }

                    // eslint-disable-next-line no-param-reassign
                    acc[item.alias] = {
                        id: item.alias,
                        price: amount,
                        currency: currencyId,
                        name: item.name,
                        quantity: item.content.quantity,
                    };

                    return acc;
                },
                {},
            );
        } catch (error) {
            console.error('Error fetching store items', error);
        }
        finally {
            state.value = PREMIUM_STORE_STATE.IDLE;
        }
    };

    const purchaseItem = async (itemId: string)=> {
        state.value = PREMIUM_STORE_STATE.PURCHASING;
        state.value = await premiumStoreService.purchaseItem(itemId);

        eventService.premiumStore.emit('purchase');
    };

    const resetState = () => state.value = PREMIUM_STORE_STATE.IDLE;

    return {
        isInited,
        state,
        storeItems,

        fetchStoreItems,
        purchaseItem,
        resetState,

        hardCurrencyPopupOpened,
        toggleHardCurrency,
    };
});
